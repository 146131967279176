import type { InjectionKey } from 'vue'
import type { Option } from '@/components/ha/HaSelectBox.vue'

// composables
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
// models
import {
  MyVketEventParameters,
  GetMyVketEventsRequest,
  GetEventParticipantsResponse,
  getEventParticipantsResponse,
  MyVketEventResponse,
  myVketEventResponse,
  GetMyVketEventsResponse,
  getMyVketEventsResponse,
  GetParticipatingMyVketEventParameter,
  PostEventRequest,
  PostEventResponse,
  postEventResponse,
  PutEventRequest,
  PutEventResponse,
  putEventResponse,
  PostLikeEventResponse,
  postLikeEventResponse,
  DeleteLikeEventResponse,
  deleteLikeEventResponse,
  GetMyVketEventsRecentlyRequest,
} from '@/composables/repository/useMyVketEventRepository'

// models
import {
  successMessageResponse,
  SuccessMessageResponse,
  MyVketEvent,
  EventParticipants,
  MyVketEventList,
  AVAILABLE_PARTICIPANT,
  SortType,
  MyVketEventSortData,
} from '@/models/myVketEvent'

// modules
import { existResponse, isValueOf } from '@/utils/zod'

export type MyVketEventStateType = {
  currentMyVketEvent: null | MyVketEvent
  participantList: EventParticipants[]
  participantCount: number
  myVketEventList: MyVketEventList
}

export const useMyVketEvent = () => {
  const repositoryFactory = useRepositoryFactory()
  const eventRepository = repositoryFactory.get('myVketEvent')
  const { uploadImage } = useTemporaryUpload()
  const i18n = useI18n()

  const state = useState<MyVketEventStateType>('my_vket_event_state', () => ({
    currentMyVketEvent: null,
    participantList: [],
    participantCount: 0,
    myVketEventList: [],
  }))

  /** 主催イベント予定の上限数 */
  const EVENT_ORGANIZER_LIMIT = 5 as const
  const limit = ref(20)
  const offset = ref(0)
  const total = ref(0)
  const isLocationSelectLoading = ref(false)

  // ▼▼▼ ソート用 ▼▼▼
  const sortType = ref<SortType>('newer')
  const sortItems: MyVketEventSortData[] = [
    {
      value: 'newer',
      text: i18n.t('sort.play-event.newer'),
    },
    {
      value: 'older',
      text: i18n.t('sort.play-event.older'),
    },
    {
      value: 'liked',
      text: i18n.t('sort.play-event.liked'),
    },
    {
      value: 'recently',
      text: i18n.t('sort.play-event.recently'),
    },
  ]
  // ▲▲▲ ソート用 ▲▲▲

  // 公開ステータスの選択肢
  const statusOptionList: Option[] = [
    {
      value: AVAILABLE_PARTICIPANT.UNLISTED,
      text: i18n.t('event.status.limited'),
    },
    {
      value: AVAILABLE_PARTICIPANT.ALL,
      text: i18n.t('event.status.public'),
    },
  ]

  const createEvent = async (eventData: PostEventRequest) => {
    const response = await eventRepository.post.createEvent(eventData)

    if (!response) {
      throw new Error('response is empty.')
    }

    if (!existResponse<PostEventResponse>(postEventResponse, response)) {
      console.error('An API response is different.')
    }

    return response
  }

  const getMyVketEvents = async (params: GetMyVketEventsRequest) => {
    const response = await eventRepository.get.getMyVketEvents(params)

    if (!response) {
      throw new Error('response is empty.')
    }

    if (
      !existResponse<GetMyVketEventsResponse>(getMyVketEventsResponse, response)
    ) {
      console.error('An API response is different.')
    }

    state.value.myVketEventList = response.events
    return response.events
  }

  const getMyVketEventsWithPager = async (
    param: GetMyVketEventsRequest & {
      page: number
    }
  ) => {
    limit.value = param.limit || limit.value
    offset.value = param.page * limit.value - limit.value

    param.limit = limit.value
    param.offset = offset.value

    const response = await eventRepository.get.getMyVketEvents({
      limit: limit.value,
      offset: offset.value,
      ...param,
    })

    if (!response) {
      throw new Error('response is empty.')
    }

    if (
      !existResponse<GetMyVketEventsResponse>(getMyVketEventsResponse, response)
    ) {
      console.error('An API response is different.')
    }

    state.value.myVketEventList = response.events
    total.value = response.eventCount

    return response.events
  }

  const getMyVketEventsRecently = async (
    params: GetMyVketEventsRecentlyRequest
  ) => {
    const response = await eventRepository.get.getMyVketEventsRecently(params)

    if (!response) {
      throw new Error('response is empty.')
    }

    if (
      !existResponse<GetMyVketEventsResponse>(getMyVketEventsResponse, response)
    ) {
      console.error('An API response is different.')
    }
    state.value.myVketEventList = response.events
    total.value = response.eventCount
    return response.events
  }

  const updateMyVketEvent = async (
    eventId: number,
    eventData: PutEventRequest
  ) => {
    const response = await eventRepository.put.putEvent(eventId, eventData)

    if (!response) {
      throw new Error('response is empty.')
    }

    if (!existResponse<PutEventResponse>(putEventResponse, response)) {
      console.error('An API response is different.')
    }

    return response
  }

  const deleteMyVketEvent = async (eventId: number) => {
    const response = await eventRepository.delete.deleteMyVketEvent(eventId)

    if (!response) {
      throw new Error('response is empty.')
    }

    if (
      !existResponse<SuccessMessageResponse>(successMessageResponse, response)
    ) {
      console.error('An API response is different.')
    }

    return response
  }

  const resistorMyVketEvent = async (eventId: number) => {
    const response = await eventRepository.post.resistorMyVketEvent(eventId)

    if (
      !existResponse<SuccessMessageResponse>(successMessageResponse, response)
    )
      return null

    if (response.success && state.value.currentMyVketEvent) {
      state.value.currentMyVketEvent.participated = true
    }

    return response
  }

  const unregisterMyVketEvent = async (eventId: number) => {
    const response = await eventRepository.delete.unregisterMyVketEvent(eventId)

    if (
      !existResponse<SuccessMessageResponse>(successMessageResponse, response)
    )
      return null

    if (response.success && state.value.currentMyVketEvent) {
      state.value.currentMyVketEvent.participated = false
    }

    return response
  }

  /**
   * 「イベント参加者一覧取得」APIを実行し結果をstateに保存する
   * @param eventId 対象イベントID
   * @param limit limit
   * @param offset offset
   * @returns 参加者一覧
   */
  const getMyVketEventParticipation = async (
    eventId: number,
    limit?: number,
    offset?: number
  ) => {
    const response = await eventRepository.get.getMyVketEventParticipation(
      eventId,
      limit,
      offset
    )

    if (
      !existResponse<GetEventParticipantsResponse>(
        getEventParticipantsResponse,
        response
      )
    ) {
      console.error('An API response is different.')
    }

    state.value.participantList = response.users
    return state.value.participantList
  }

  /**
   * 「イベント参加者一覧取得」APIを実行し結果をstateに保存する
   * @param eventId 対象イベントID
   * @param limit limit
   * @param offset offset
   * @returns 参加者一覧
   */
  const getMyVketEventParticipationWithInfiniteScroll = async (
    eventId: number,
    limit?: number,
    offset?: number
  ) => {
    const response = await eventRepository.get.getMyVketEventParticipation(
      eventId,
      limit,
      offset
    )

    if (
      !existResponse<GetEventParticipantsResponse>(
        getEventParticipantsResponse,
        response
      )
    ) {
      console.error('An API response is different.')
    }

    state.value.participantList.push(...response.users)
    return state.value.participantList
  }

  const getParticipatingMyVketEvent = async (
    params: GetParticipatingMyVketEventParameter
  ) => {
    const response = await eventRepository.get.getParticipatingMyVketEvent(
      params
    )

    if (!response) {
      throw new Error('response is empty.')
    }

    if (
      !existResponse<GetMyVketEventsResponse>(getMyVketEventsResponse, response)
    ) {
      console.error('An API response is different.')
    }

    state.value.myVketEventList = response.events

    return response.events
  }

  const getParticipatingMyVketEventWithPager = async (
    param: GetParticipatingMyVketEventParameter & {
      page: number
    }
  ) => {
    limit.value = param.limit || limit.value
    offset.value = param.page * limit.value - limit.value

    param.limit = limit.value
    param.offset = offset.value

    const response = await eventRepository.get.getParticipatingMyVketEvent({
      limit: limit.value,
      offset: offset.value,
      ...param,
    })

    if (!response) {
      throw new Error('response is empty.')
    }

    if (!isValueOf(getMyVketEventsResponse, response)) {
      console.error('An API response is different.')
    }

    state.value.myVketEventList = response.events
    total.value = response.eventCount

    return response.events
  }

  /**
   * 「イベント詳細」APIを実行し結果をstateに保存する
   * @param eventId 対象のイベントID
   * @returns イベント詳細
   */
  const getMyVketEventDetail = async (eventId: number) => {
    const response = await eventRepository.get.getMyVketEvent(eventId)

    if (!isValueOf(myVketEventResponse, response)) {
      console.error('An API response is different.')
    }

    state.value.currentMyVketEvent = response.event
    state.value.participantCount = response.event.numberOfParticipants

    return state.value.currentMyVketEvent
  }

  /**
   * イベントにいいね
   * @returns success message
   */
  const likeMyVketEvent = async (eventId: number) => {
    const response = await eventRepository.post.likeMyVketEvent(eventId)

    if (!existResponse<PostLikeEventResponse>(postLikeEventResponse, response))
      return null

    changeMyVketEventLikeState(eventId, true)

    return response
  }

  /**
   * イベントにいいね削除
   * @returns success message
   */

  const unlikeMyVketEvent = async (eventId: number) => {
    const response = await eventRepository.delete.unlikeMyVketEvent(eventId)

    if (
      !existResponse<DeleteLikeEventResponse>(deleteLikeEventResponse, response)
    )
      return null

    changeMyVketEventLikeState(eventId, false)

    return response
  }

  /**
   * 「いいね」状態更新
   * @param eventId
   * @param likeState 更新後のいいね状態
   */
  const changeMyVketEventLikeState = (eventId: number, likeState: boolean) => {
    if (state.value.currentMyVketEvent?.id === eventId) {
      state.value.currentMyVketEvent.liked = likeState
      if (likeState) {
        state.value.currentMyVketEvent.numberOfLikes++
      } else {
        state.value.currentMyVketEvent.numberOfLikes--
      }
    }

    const targetMyVketEvent = state.value.myVketEventList.find((event) => {
      return event.id === eventId
    })
    if (targetMyVketEvent) {
      targetMyVketEvent.liked = likeState
      if (likeState) {
        targetMyVketEvent.numberOfLikes++
      } else {
        targetMyVketEvent.numberOfLikes--
      }
    }
  }

  /**
   * ソートタイプを更新する
   */
  const updateSortType = (type: SortType) => {
    sortType.value = type
  }

  return {
    state: readonly(state),
    EVENT_ORGANIZER_LIMIT,
    limit,
    offset,
    total,
    sortType: readonly(sortType),
    sortItems,
    isLocationSelectLoading,
    statusOptionList,
    createEvent,
    getMyVketEvents,
    getMyVketEventsWithPager,
    getMyVketEventsRecently,
    updateMyVketEvent,
    deleteMyVketEvent,
    resistorMyVketEvent,
    unregisterMyVketEvent,
    getMyVketEventParticipation,
    getParticipatingMyVketEvent,
    getParticipatingMyVketEventWithPager,
    getMyVketEventDetail,
    likeMyVketEvent,
    unlikeMyVketEvent,
    getMyVketEventParticipationWithInfiniteScroll,
    updateSortType,
  }
}

export type MyVketEventComposable = ReturnType<typeof useMyVketEvent>

export const myVketEventComposableInjectionKey: InjectionKey<MyVketEventComposable> =
  Symbol('my-vket-event-composable')

// Note: 詳細画面のこのユーザーの他のイベントで利用
export const myVketEventComposableInjectionKeyUser: InjectionKey<MyVketEventComposable> =
  Symbol('my-vket-event-composable-user')
// Note: 詳細画面の他の人気イベントで利用
export const myVketEventComposableInjectionKeyOther: InjectionKey<MyVketEventComposable> =
  Symbol('my-vket-event-composable-other')
